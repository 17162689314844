import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, computed } from 'mobx';
import { Label } from 'reactstrap';
import { FaFilter, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';

import { Checkbox, GetDataField } from '@Components';
import { ColumnFilters, ColumnSort } from '../FilterSection/FilterSectionStore';
import './filter-popup.scss';

export type FilterValue = string | number | ColumnFilters;
export type FilterOption = { name: string, value: FilterValue };
/* eslint-disable @typescript-eslint/no-explicit-any */
export type ColumnFiltersMap = Map<string, { filterValues: FilterValue[], getDataField?: GetDataField }>;
export type ColumnSortModel = { sortType: ColumnSort, dataField: string, getDataField?: GetDataField };
/* eslint-disable @typescript-eslint/no-explicit-any */

type FilterPopupProps = {
    dataField: string;
    filterOptions?: FilterOption[];
    isFilterActive: boolean;
    disableSorting?: boolean;
    columnSort: ColumnSortModel | null;
    columnFilters: ColumnFiltersMap;
    onChangeSort: (sortType: ColumnSort) => void;
    onChangeFilters: (filterValue: FilterValue | number) => void;
};

@observer
export class FilterPopup extends React.Component<FilterPopupProps, {}> {
    @observable private _isVisible: boolean = false;
    @observable private _popupEvent: React.MouseEvent | null = null;
    private _popupWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: FilterPopupProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this._handleClickOutside);
        window.addEventListener('resize', this._onDisappear);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._handleClickOutside);
        window.removeEventListener('resize', this._onDisappear);
    }

    @action.bound
    private _onDisappear() {
        this._isVisible = false;
    }

    @action.bound
    private _handleClickOutside(event: MouseEvent) {
        if (this._isVisible && !this._popupWrapperRef.current?.contains(event.target as Node)) {
            this._onDisappear();
        }
    }

    render() {
        const clickX = this._popupEvent?.clientX || 100;
        const clickY = this._popupEvent?.clientY || 100;

        const screenW = window.innerWidth;
        const popupWidth = 120;

        let left = clickX + 5;
        let top = clickY + 5;

        if ((screenW - clickX) < popupWidth) {
            left = clickX - popupWidth - 5;
        }

        const maxHeight = this._getMaxPopupHeight(top);
        const { isFilterActive } = this.props;

        return (
            <>
                <FaFilter
                    className={`filter-icon ${isFilterActive ? 'active' : ''}`}
                    onClick={this._toggleFilterPopupHandler}
                />
                {this._isVisible && (
                    <div ref={this._popupWrapperRef} className="filter-popup" style={{ left, top, maxHeight }}>
                        {this._renderSortOptions()}
                        {this._renderFilterOptions()}
                    </div>
                )}
            </>
        );
    }

    @action.bound
    private _toggleFilterPopupHandler(e: React.MouseEvent) {
        this._popupEvent = e;
        this._isVisible = true;
    }

    private _renderSortOptions() {
        const { columnSort, dataField } = this.props;

        return this._sortOptions.map((s) => {
            const isActive = columnSort?.dataField === dataField && columnSort?.sortType === s.value;
            return (
                <div
                    key={s.value}
                    className={`sort-item-wrapper${isActive ? ' active' : ''}`}
                    onClick={() => this._changeSortHandler(s.value)}
                >
                    {s.icon}
                    <span >{s.name}</span>
                </div>
            );
        });
    }

    private _renderFilterOptions() {
        const { columnFilters, dataField } = this.props;

        return this._filterOptions.map(f => {
            const isChecked = !!columnFilters.get(dataField)?.filterValues.includes(f.value);
            return (
                <Label
                    key={f.value.toString()}
                    className="filter-item-wrapper"
                >
                    <Checkbox
                        style={{ width: 'unset', height: 'unset' }}
                        checked={isChecked}
                        onChange={() => this._changeFiltersHandler(f.value)}
                    />
                    <span>{f.name}</span>
                </Label>
            );
        });
    }

    private _getMaxPopupHeight(popupTop: number) {
        return window.innerHeight - popupTop - 100;
    }

    @computed
    private get _sortOptions() {
        if (this.props.disableSorting) return [];

        return [
            { name: 'Sort ascending', value: ColumnSort.ASC, icon: <FaSortAlphaDown /> },
            { name: 'Sort descending', value: ColumnSort.DESC, icon: <FaSortAlphaDownAlt /> }
        ];
    }

    @computed
    private get _filterOptions() {
        const defaultOptions = [
            { name: 'With values', value: ColumnFilters.WithValues },
            { name: 'Without values', value: ColumnFilters.WithoutValues }
        ];

        const options = this.props.filterOptions || [];
        return [...defaultOptions, ...options];
    }

    private _changeSortHandler(sort: ColumnSort) {
        this.props.onChangeSort(sort);
    }

    private _changeFiltersHandler(filterValue: FilterValue) {
        this.props.onChangeFilters(filterValue);
    }
}
